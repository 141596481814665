import React, { useEffect, useState, useRef } from "react";
import { graphql, StaticQuery, navigate } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import "../utils/normalize.css";
import "../utils/css/screen.css";

import {
  BannerTopLeft,
  BannerTopLeftImage,
  Canvas
} from "../components/styledComponents";
import BannerImage from "../assets/landing/sculpture-top-banner.png";
import DraggableBlocks from "../components/Landing/DraggableBlocks";
import { Footer } from "../components/footer";
import { isMobile } from "react-device-detect";
// import { Campaigns } from "../components/Campaigns/Campaigns";
// import { DriveThru } from "../components/SpecialEvents/DriveThru"

const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  // const [visible, setVisible] = useState();
  // const handleHide = () => {
  //   sessionStorage.setItem("campaignVisible", false);
  //   setVisible(false);
  // };
  const [lastX, setLastX] = useState();
  const [lastY, setLastY] = useState();
  let CanvasRef = useRef();

  function resize() {
    if (typeof window !== undefined) {
      let ctx = CanvasRef.current.getContext("2d");
      ctx.canvas.width = window.innerWidth;
      ctx.canvas.height = window.innerHeight;
    }
  }

  function draw(ctx, x, y) {
    ctx.beginPath();
    ctx.moveTo(lastX, lastY);
    ctx.lineTo(x, y);
    ctx.closePath();
    ctx.stroke();
  }

  function handleMouseMove(e) {
    var xy = getMousePos(e);
    if (e.buttons !== 1) {
      let ctx = CanvasRef.current.getContext("2d");
      draw(ctx, xy.mouseX, xy.mouseY);
      setLastX(xy.mouseX);
      setLastY(xy.mouseY);
    } else {
      setLastX(xy.mouseX);
      setLastY(xy.mouseY);
    }
  }

  function getMousePos(e) {
    var o = {};
    if (e.clientX) {
      o.mouseX = e.clientX;
      o.mouseY = e.clientY;
    } else if (e.layerX) {
      o.mouseX = e.layerX;
      o.mouseY = e.layerY;
    }
    return o;
  }

  useEffect(() => {
    resize();
    if (window) {
      window.addEventListener("scroll", resize());
      window.addEventListener("resize", resize());
    }
    return () => {
      window.removeEventListener("scroll", resize());
      window.removeEventListener("resize", resize());
    };
  }, []);

  return (
    <>
      <div
        onMouseMove={handleMouseMove}
        style={{ widht: "100%", height: "100%" }}
        aria-hidden="true"
      >
        <Layout title={siteTitle} siteBgColor={"#FDF8E2"}>
          <SEO
            title="Sculpture | Photobooth"
            keywords={[`sculpture`, `photobooth`, `ตู้ถ่ายรูป`, `ถ่ายรูป`]}
          />
          <BannerTopLeft>
            <BannerTopLeftImage
              src={BannerImage}
              alt="sculpturebangkok"
              onClick={() => navigate("/about")}
            />
          </BannerTopLeft>
        </Layout>
        <DraggableBlocks />
        <Canvas ref={CanvasRef} />
        {/* <Sheet /> */}
        {!isMobile && <Footer />}
      </div>

      {/* <Campaigns visible={visible} handleHide={handleHide} /> */}
    </>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
);
