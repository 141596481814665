import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import Draggable from "react-draggable";
import LocationMobile from "../../../assets/landing/locations-mobile.png";
import StoreMobile from "../../../assets/landing/random-sculpture-mobile.png";
import SnapMobile from "../../../assets/landing/snap-mobile.png";
import LoveMobile from "../../../assets/landing/love-mobile.png";
import LocationDesktop from "../../../assets/landing/locations-desktop2.png";
import StoreDesktop from "../../../assets/landing/random-sculpture-desktop2.png";
import SnapDesktop from "../../../assets/landing/snap-desktop2.png";
import LoveDesktop from "../../../assets/landing/love-desktop2.png";

import {
  Box,
  BoxContainer,
  BoxText,
  moveAnimationOne,
  moveAnimationTwo,
  moveAnimationFour,
  moveAnimationFive
} from "../../styledComponents";

const ImagesArrayMobile = [
  {
    image: LocationMobile,
    position: { left: "5%", top: "13%" },
    width: 200,
    animation: moveAnimationOne,
    path: "/locations"
  },
  {
    image: SnapMobile,
    position: { left: "35%", top: "59%" },
    width: 200,
    animation: moveAnimationFour,
    path: "https://snapphotoservice.com/"
  },
  {
    image: LoveMobile,
    position: { left: "2%", top: "41%" },
    width: 200,
    animation: moveAnimationFive,
    path: "/wedding"
  },
  {
    image: StoreMobile,
    position: { left: "43%", top: "26%" },
    width: 200,
    animation: moveAnimationTwo,
    path: "/store"
  }
];

const ImagesArrayIpad = [
  {
    image: LoveDesktop,
    title: "Wedding photo service",
    color: "white",
    position: { left: "55%", top: "29%" },
    width: 320,
    animation: moveAnimationFive,
    path: "/wedding"
  },
  {
    image: SnapDesktop,
    title: "Photobooth service",
    color: "white",
    position: { left: "32%", top: "55%" },
    width: 320,
    animation: moveAnimationFour,
    path: "https://snapphotoservice.com/"
  },
  {
    image: LocationDesktop,
    title: "See all locations",
    color: "white",
    position: { left: "28%", top: "18%" },
    width: 320,
    animation: moveAnimationOne,
    path: "/locations"
  },
  {
    image: StoreDesktop,
    title: "Shop our store",
    color: "black",
    position: { left: "2%", top: "35%" },
    width: 320,
    animation: moveAnimationTwo,
    path: "/store"
  }
];

const ImagesArrayDesktop = [
  {
    image: LoveDesktop,
    title: "Wedding photo service",
    color: "white",
    position: { left: "60%", top: "22%" },
    width: 384,
    animation: moveAnimationFive,
    path: "/wedding"
  },
  {
    image: SnapDesktop,
    title: "Photobooth service",
    color: "white",
    position: { left: "37%", top: "49%" },
    width: 384,
    animation: moveAnimationFour,
    path: "https://snapphotoservice.com/"
  },
  {
    image: LocationDesktop,
    title: "See all locations",
    color: "white",
    position: { left: "33%", top: "7%" },
    width: 384,
    animation: moveAnimationOne,
    path: "/locations"
  },
  {
    image: StoreDesktop,
    title: "Shop our store",
    color: "black",
    position: { left: "7%", top: "27%" },
    width: 384,
    animation: moveAnimationTwo,
    path: "/store"
  }
];

const DraggableBlocks = () => {
  const [layoutData, setLayoutData] = useState();
  const [toggleNav, setToggleNav] = useState(false);
  const [drag, setDrag] = useState(false);
  const [animation] = useState(false);

  const handleClick = path => {
    if (!drag) {
      navigate(path);
    }
    setDrag(false);
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      setToggleNav(true);
    }
    if (window.innerWidth <= 480) {
      setLayoutData(ImagesArrayMobile);
    } else if (window.innerWidth <= 1030) {
      setLayoutData(ImagesArrayIpad);
    } else {
      setLayoutData(ImagesArrayDesktop);
    }
  }, []);

  return (
    <>
      {layoutData &&
        layoutData.map(item => {
          return (
            <Draggable
              key={item.title}
              bounds="parent"
              defaultPosition={{ x: 10, y: 10 }}
              onDrag={() => setDrag(true)}
              onStop={() => handleClick(item.path)}
            >
              <BoxContainer
                style={item.position}
                animation={animation ? item.animation : ""}
                visible={toggleNav}
              >
                <Box
                  id={"draggable-item"}
                  image={item.image}
                  width={item.width}
                >
                  <BoxText color={item.color}>{item.title}</BoxText>
                </Box>
              </BoxContainer>
            </Draggable>
          );
        })}
    </>
  );
};

export default DraggableBlocks;
